import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

/**APP IMPORTS */
import { AddProjectSteps } from '@app_modules/Projects/components/AddProject';

/**CORE IMPORTS */
import { ExactTitle, FallBackLoaders } from '@core_common/components';

function AddProject() {
  const { t } = useTranslation('common');

  return (
    <>
      {' '}
      <ExactTitle level={3} text={t('Create a project')} />
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <AddProjectSteps />
      </Suspense>
    </>
  );
}

export default AddProject;
