import { lazy } from 'react';

const AddProjectSteps = lazy(() => import('./AddProjectSteps'));
const AppDetailsForm = lazy(() => import('./AppDetailsForm'));
const AestheticsForm = lazy(() => import('./AestheticsForm'));
const UploadImages = lazy(() => import('./UploadImages'));
const DatabaseForm = lazy(() => import('./DatabaseForm'));
const ModuleForm = lazy(() => import('./ModuleForm'));
const ModuleFormList = lazy(() => import('./ModuleFormList'));
const DatabaseTabs = lazy(() => import('./DatabaseTabs'));
const Finished = lazy(() => import('./Finished'));

/**ICONS */
const IconsGroupList = lazy(() => import('./IconsGroupList'));
const IconsList = lazy(() => import('./IconsList'));
const DirectionalIcons = lazy(() => import('./DirectionalIcons'));
const SuggestedIcons = lazy(() => import('./SuggestedIcons'));
const EditorIcons = lazy(() => import('./EditorIcons'));
const DataIcons = lazy(() => import('./DataIcons'));
const BrandAndLogoIcons = lazy(() => import('./BrandAndLogoIcons'));
const ApplicationIcons = lazy(() => import('./ApplicationIcons'));

export {
  AddProjectSteps,
  AppDetailsForm,
  AestheticsForm,
  UploadImages,
  DatabaseForm,
  ModuleForm,
  DatabaseTabs,
  Finished,
  DirectionalIcons,
  IconsGroupList,
  IconsList,
  SuggestedIcons,
  EditorIcons,
  DataIcons,
  BrandAndLogoIcons,
  ApplicationIcons,
  ModuleFormList
};
