import { types } from 'mobx-state-tree';
import Axios from 'axios';

/**CORE IMPORTS */

const API_PATH = '/api/app_images';

export default types
  .model('AppImagesModel', {
    project_id: types.optional(types.number, 0),
    background_image: types.optional(types.string, ''),
    company_logo: types.optional(types.string, '')
  })
  .views((self) => ({}))
  .actions((self) => ({
    async deleteImage(project_id, fileName, formType) {
      try {
        let { data } = await Axios.delete(`${API_PATH}/delete`, {
          params: { project_id, fileName, formType }
        });

        return [data, null];
      } catch (error) {
        return [null, error];
      }
    }
  }));
