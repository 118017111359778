import { types, flow } from 'mobx-state-tree';

/**CORE IMPORTS */
import ApplyChildCrud from '@core_models/Helpers/ChildCrud';
import axios from 'axios';

const API_PATH = '/api/module';

export default types
  .model('ModuleModel', {
    project_id: types.optional(types.number, 0),
    tempId: types.optional(types.string, ''),
    module_name: types.optional(types.string, ''),
    menu_name: types.optional(types.string, ''),
    icon: types.optional(types.string, ''),
    icon_name: types.optional(types.string, ''),
    route: types.optional(types.string, ''),
    isUpdating: types.optional(types.boolean, false),
    currentUpdateId: types.optional(types.string, ''),
    isCreating: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    ...ApplyChildCrud(API_PATH, self),

    CREATE_MODULE: flow(function* (values) {
      try {
        self.isCreating = true;
        const { data } = yield axios.post(API_PATH, values);

        self.isCreating = false;
        return [data, null];
      } catch (error) {
        self.isCreating = false;
        return [null, error];
      }
    }),

    setIsUpdating(isUpdating) {
      self.isUpdating = isUpdating;
    },

    setCurrentUpdateId(currentUpdateId) {
      self.currentUpdateId = currentUpdateId;
    }
  }));
