import { types } from 'mobx-state-tree';

/**CORE IMPORTS */
import ApplyChildCrud from '@core_models/Helpers/ChildCrud';

const API_PATH = '/api/database_config';

export default types
  .model('AestheticsModel', {
    project_id: types.optional(types.number, 0),
    host_name_local: types.optional(types.string, ''),
    domain_name_local: types.optional(types.string, ''),
    port_number_local: types.optional(types.string, ''),
    db_name_local: types.optional(types.string, ''),
    db_user_name_local: types.optional(types.string, ''),
    db_password_local: types.optional(types.maybeNull(types.string), ''),

    host_name_dev: types.optional(types.string, ''),
    domain_name_dev: types.optional(types.string, ''),
    port_number_dev: types.optional(types.string, ''),
    db_name_dev: types.optional(types.string, ''),
    db_user_name_dev: types.optional(types.string, ''),
    db_password_dev: types.optional(types.maybeNull(types.string), ''),

    host_name_staging: types.optional(types.string, ''),
    domain_name_staging: types.optional(types.string, ''),
    port_number_staging: types.optional(types.string, ''),
    db_name_staging: types.optional(types.string, ''),
    db_user_name_staging: types.optional(types.string, ''),
    db_password_staging: types.optional(types.maybeNull(types.string), ''),

    host_name_prod: types.optional(types.string, ''),
    domain_name_prod: types.optional(types.string, ''),
    port_number_prod: types.optional(types.string, ''),
    db_name_prod: types.optional(types.string, ''),
    db_user_name_prod: types.optional(types.string, ''),
    db_password_prod: types.optional(types.maybeNull(types.string), ''),

    tabActiveKey: types.optional(types.number, 0)
  })
  .views((self) => ({}))
  .actions((self) => ({
    ...ApplyChildCrud(API_PATH, self),

    setNextTabActiveKey(nextActiveKey) {
      self.tabActiveKey = nextActiveKey;
    }
  }));
