import React, { Suspense, useEffect } from 'react';
import { Col, Divider, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

/**APP IMPORTS*/
import {
  AppIcon,
  AppDetails,
  AppImageGallery,
  AestheticsDetails,
  DatabaseDetails,
  ModulesDetails
} from '@app_modules/Projects/components/AppView';

/**CORE IMPORTS */
import { ExactCard, ExactTitle, FallBackLoaders } from '@core_common/components';
import { ProjectController } from '../controller';

const { TabPane } = Tabs;

function AppView({ store }) {
  const params = useParams();
  const { t } = useTranslation('common');

  const { getSingleProject } = ProjectController({ store, params });

  // eslint-disable-next-line
  useEffect(getSingleProject, []);

  function callback(key) {}

  return (
    <>
      <ExactTitle level={3} text={t('App View')} />
      <ExactCard>
        <Row gutter={[16, 16]}>
          <Col className="text-center" md={{ span: 5 }}>
            <Suspense fallback={FallBackLoaders.EmptyLoader}>
              <AppIcon />
            </Suspense>
          </Col>
          <Col md={{ span: 19 }}>
            <Suspense fallback={FallBackLoaders.EmptyLoader}>
              <AppDetails />
            </Suspense>
          </Col>
        </Row>
        <Divider />
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Images" key="1">
            <Suspense fallback={FallBackLoaders.EmptyLoader}>
              <AppImageGallery />
            </Suspense>
          </TabPane>
          <TabPane tab="Aesthetics" key="2">
            <Suspense fallback={FallBackLoaders.EmptyLoader}>
              <AestheticsDetails />
            </Suspense>
          </TabPane>
          <TabPane tab="Modules" key="3">
            <Suspense fallback={FallBackLoaders.EmptyLoader}>
              <ModulesDetails />
            </Suspense>
          </TabPane>
          <TabPane tab="Database config" key="4">
            <Suspense fallback={FallBackLoaders.EmptyLoader}>
              <DatabaseDetails />
            </Suspense>
          </TabPane>
        </Tabs>
      </ExactCard>
    </>
  );
}

export default inject('store')(observer(AppView));
