import React, { Suspense } from 'react';

/**APP IMPORTS */
import { TranslationTable } from '@app_modules/Translation/components';

/**CORE IMPORTS */
import { ExactTitle, FallBackLoaders } from '@core_common/components';

function Translation() {
  return (
    <>
      <ExactTitle level={2} text="Translation" />

      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <TranslationTable />
      </Suspense>
    </>
  );
}

export default Translation;
