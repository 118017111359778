import { lazy } from 'react';

const AppIcon = lazy(() => import('./AppIcon'));
const AppDetails = lazy(() => import('./AppDetails'));
const AppImageGallery = lazy(() => import('./AppImageGallery'));
const AestheticsDetails = lazy(() => import('./AestheticsDetails'));
const DatabaseDetails = lazy(() => import('./DatabaseDetails'));
const ModulesDetails = lazy(() => import('./ModulesDetails'));

export { AppIcon, AppDetails, AppImageGallery, AestheticsDetails, DatabaseDetails, ModulesDetails };
