const PROJECTS_ROUTE = '/projects';
const ADD_PROJECT_ROUTE = '/project/add';
const PROJECT_VIEW_ROUTE = '/project/view/:projectId';

const TRANSLATION_VIEW_ROUTE = '/translation';

const Routes = {
  PROJECTS_ROUTE,
  ADD_PROJECT_ROUTE,
  PROJECT_VIEW_ROUTE,
  TRANSLATION_VIEW_ROUTE
};
export default Routes;
