import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

/**APP */
import { Projects } from '@app_modules/';
import { AddProject, AppView } from '@app_modules/Projects/view';
import { Translation } from '@app_modules/Translation/view';
import { AppRoutes } from '@app_routing/';
import '@app_assets/css/app-styles.css';

/**APP CORE */
import '@core_assets/css/exact-styles.css';
import '@core_assets/css/App.css';
import '@core_assets/css/App.less';
import '@core_assets/css/antd-custom.css';

import { PrivateRoute, Routes } from '@core_routing/';
import {
  AdminSettings,
  AdminLayout,
  SignInLayout,
  UserManagement,
  Login,
  ResetPassword,
  ChangePassword,
  Error404
} from '@core_modules/';

function App({ store }) {
  let { login } = store;

  console.log(Routes, 'sdfsdf');

  return (
    <Router>
      <Switch>
        {!login.isLoading && (
          <>
            <PrivateRoute
              exact
              path={Routes.HOME_ROUTE}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={Projects}
              Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={AppRoutes.ADD_PROJECT_ROUTE}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={AddProject}
              Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={AppRoutes.PROJECT_VIEW_ROUTE}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={AppView}
              Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={AppRoutes.TRANSLATION_VIEW_ROUTE}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={Translation}
              Layout={AdminLayout}
            />

            <PrivateRoute
              exact
              path={Routes.USER_MANAGEMENT_ROUTE}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={UserManagement}
              Layout={AdminLayout}
            />

            <PrivateRoute
              exact
              path={Routes.HOME_ROUTE}
              allowed={['Administrator']}
              isAuthorized={true}
              login={login}
              Component={UserManagement}
              Layout={AdminLayout}
            />
            <PrivateRoute
              exact
              path={Routes.LOGIN_ROUTE}
              Component={Login}
              isAuthorized={false}
              login={login}
              allowed={['GUEST']}
              Layout={SignInLayout}
            />

            <PrivateRoute
              exact
              path={Routes.RESET_PASSWORD_ROUTE}
              Component={ResetPassword}
              isAuthorized={false}
              login={login}
              allowed={['GUEST']}
              Layout={SignInLayout}
            />
            <PrivateRoute
              exact
              path={Routes.CHANGE_PASSWORD_ROUTE}
              Component={ChangePassword}
              isAuthorized={false}
              login={login}
              allowed={['GUEST']}
              Layout={SignInLayout}
            />
            <PrivateRoute
              exact
              path={Routes.ADMIN_SETTINGS_ROUTE}
              Component={AdminSettings}
              isAuthorized={true}
              login={login}
              allowed={['Administrator']}
              Layout={AdminLayout}
            />

            <Route path="*" exact component={Error404} />
          </>
        )}
      </Switch>
    </Router>
  );
}

export default inject('store')(observer(App));
