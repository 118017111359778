import { types } from 'mobx-state-tree';
import AestheticsModel from './AestheticsModel';
import AppImagesModel from './AppImagesModel';
import DatabaseConfigModel from './DatabaseConfigModel';
import ModuleModel from './ModuleModel';

export default types
  .model('ProjectsModel', {
    id: types.optional(types.number, 0),
    company_name: types.optional(types.string, ''),
    company_address: types.optional(types.string, ''),
    company_number: types.optional(types.string, ''),
    company_email_address: types.optional(types.string, ''),
    company_email_address_password: types.optional(types.string, ''),
    app_name: types.optional(types.string, ''),
    app_description: types.optional(types.string, ''),
    Aesthetics: types.optional(types.maybeNull(AestheticsModel), {}),
    AppImages: types.optional(types.maybeNull(AppImagesModel), {}),
    DatabaseConfig: types.optional(types.maybeNull(DatabaseConfigModel), {}),
    Module: types.optional(types.maybeNull(types.array(ModuleModel)), [])
  })
  .views((self) => ({}))
  .actions((self) => ({}));
