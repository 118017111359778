import { AppRoutes } from '@app_routing/';
import { message } from 'antd';

const ProjectController = ({ store, history, params, t, setFileList, required, form }) => {
  const getProjects = async () => {
    store.projects.setIsGettingProjects(true);

    const page = 1;

    const search = '';
    const props = [];
    let params = { page, search, props };

    await store.projects.LIST(params);
    store.projects.setIsGettingProjects(false);
  };

  const getSingleProject = async () => {
    await store.projects.RETRIEVE(params.projectId, false);
  };

  const getDevelopersEmail = async () => {
    let [result, _] = await store.users.LIST({}, true);

    store.projects.setDevEmails(result);
  };

  const handleNavigateToCreateProject = () => {
    return history.push(AppRoutes.ADD_PROJECT_ROUTE);
  };

  const handleSubmitAppDetails = async (args) => {
    message.loading({ content: t('Submiting app details'), key: 'create' });
    store.projects.setIsAppDetailsSubmitting(true);

    args.app_dev_email = `${args.app_dev_email}`;

    let [successMessage, error] = await store.projects.CREATE(args);

    let success = await _showresultMessage(error, {
      successMessage: t(successMessage.message) // isUpdate ? t('User Updated') :
    });

    store.projects.setIsAppDetailsSubmitting(false);
    if (success) {
      store.projects.setSingle(successMessage.data);
      store.projects.setNextStep('1');
    }
  };

  const handleBeginDownlaodApp = async () => {
    store.projects.setDownloadingApp(true);

    store.projects.setDownloadButtonName('Cloning App');
    let [result, error] = await store.projects.beginClone();

    if (result.success) {
      store.projects.setDownloadButtonName(result.message);

      let [result2, error] = await store.projects.beginMigration();

      if (result2.success) {
        store.projects.setDownloadButtonName(result2.message);

        let [result3, error] = await store.projects.finalizingApp(params.projectId);

        if (result3.success) {
          store.projects.setDownloadButtonName(result3.message);

          let [result4, error] = await store.projects.beginZipping();

          if (result4.success) {
            store.projects.setDownloadButtonName(result4.message);

            let [success, error] = await store.projects.beginDownload(params.projectId);
            if (error) {
              console.log(error);
            } else {
              await store.projects.startDownload(success.data.data.repoName);
              store.projects.setDownloadingApp(false);
            }
          }
        }
      }
    }
  };

  const handleSumbitAesthethics = async (args) => {
    message.loading({ content: t('Submiting app aesthetics'), key: 'create' });

    args.project_id = store.projects.single.id;

    let [successMessage, error] = await store.projects.aesthetics.CREATE(args);

    let success = await _showresultMessage(error, {
      successMessage: t(successMessage.message) // isUpdate ? t('User Updated') :
    });

    if (success) {
      store.projects.setNextStep('2');
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const onRemoveOrDeleteImage = async (file, formType) => {
    let [successMessage, error] = await store.projects.appImages.deleteImage(
      store.projects.single.id,
      file.response.fileName,
      formType
    );

    await _showresultMessage(error, {
      successMessage: t(successMessage.message) // isUpdate ? t('User Updated') :
    });
  };

  const generateDbDataFormData = (type) => {
    let data = {
      host: {
        name: `host_name_${type}`,
        label: 'Host Name',
        rules: [required(t('Please insert host name'))],
        placeholder: t('Insert host name')
      },
      domain: {
        name: `domain_name_${type}`,
        label: 'Domain Name',
        rules: [required(t('Please insert domain name'))],
        placeholder: t('Insert domain name')
      },

      port: {
        name: `port_number_${type}`,
        label: 'Port Number',
        rules: [required(t('Please insert port number'))],
        placeholder: t('Insert port number')
      },

      databaseName: {
        name: `db_name_${type}`,
        label: 'Database Name',
        rules: [required(t('Please insert database name'))],
        placeholder: t('Insert database name')
      },

      databaseUser: {
        name: `db_user_name_${type}`,
        label: 'Database User Name',
        rules: [required(t('Please insert database user name'))],
        placeholder: t('Insert database user name')
      },

      databasePassword: {
        name: `db_password_${type}`,
        label: 'Database password',
        rules: [],
        placeholder: t('Insert database password')
      }
    };

    return data;
  };

  const handleSubmitAppDbDetails = async (args) => {
    args.project_id = store.projects.single.id;

    let [success, error] = await store.projects.databaseConfig.CREATE(args);

    if (!error) {
      if (store.projects.databaseConfig.tabActiveKey === 3) {
        store.projects.setNextStep('5');
      } else {
        store.projects.databaseConfig.setNextTabActiveKey(
          store.projects.databaseConfig.tabActiveKey + 1
        );
      }
    } else {
      console.log(error);
    }
  };

  const handleNextStepAfterImageUpload = () => {
    store.projects.setNextStep('3');
  };

  const handleChangeTab = (key) => {
    store.projects.databaseConfig.setNextTabActiveKey(parseInt(key));
  };

  const generateColumnsForDbDetails = (type) => {
    const replaceString = 'REPLACE';
    let data = [
      {
        title: 'Host name',
        dataIndex: 'host_name_REPLACE',
        key: 'host_name_REPLACE',
        width: '17%'
      },
      {
        title: 'Domain name',
        dataIndex: 'domain_name_REPLACE',
        key: 'domain_name_REPLACE',
        width: '17%'
      },
      {
        title: 'Port number',
        dataIndex: 'port_number_REPLACE',
        key: 'port_number_REPLACE',
        width: '17%'
      },
      {
        title: 'Database name',
        dataIndex: 'db_name_REPLACE',
        key: 'db_name_REPLACE',
        width: '17%'
      },
      {
        title: 'Database user',
        dataIndex: 'db_user_name_REPLACE',
        key: 'db_user_name_REPLACE',
        width: '17%'
      },
      {
        title: 'Database password',
        dataIndex: 'db_password_REPLACE',
        key: 'db_password_REPLACE',
        width: '17%'
      }
    ];

    data = data.map((d) => {
      return {
        // title: t(d.title.replace(replaceString, type)),
        ...d,
        dataIndex: d.dataIndex.replace(replaceString, type),
        key: d.key.replace(replaceString, type)
      };
    });

    return data;
  };

  const toggleIconsModal = () => {
    store.projects.setShowIconsModal(!store.projects.showIconsModal);
  };

  const handleChooseAnIcon = (args) => {
    store.projects.setCurrentIcon(args.name);
    form.setFieldsValue({ module_icon: args.name });
    toggleIconsModal();
  };

  const handleSubmitModuleDetails = (args) => {
    if (store.projects.module.isUpdating) {
      _performUpdate(args);
    } else {
      _performAdd(args);
    }
  };

  const handleRemoveModule = (module) => {
    store.projects.removeModuleFromList(module);
  };

  const handleUpdateModule = (module) => {
    form.setFieldsValue({
      module_name: module.module_name,
      module_icon: module.icon_name
    });

    store.projects.module.setIsUpdating(true);
    store.projects.module.setCurrentUpdateId(module.tempId);

    store.projects.setCurrentIcon(module.icon_name);
  };

  const handleSubmitModule = async () => {
    let [successMessage, error] = await store.projects.module.CREATE_MODULE(
      store.projects.moduleList
    );

    let success = await _showresultMessage(error, {
      successMessage: t(successMessage.message) // isUpdate ? t('User Updated') :
    });
    if (success) {
      store.projects.setNextStep('4');
    }
  };

  function _performUpdate(args) {
    let moduleIsTaken = store.projects.moduleList.find(
      (d) =>
        d.module_name.toLowerCase() === args.module_name.toLowerCase() &&
        d.tempId !== store.projects.module.currentUpdateId
    );

    if (moduleIsTaken) {
      message.warning({ content: 'Ooops! This Module already Exist!' });
    } else {
      form.resetFields();
      store.projects.setCurrentIcon('');
      store.projects.setUpdateModuleList(args);
      store.projects.module.setCurrentUpdateId('');
      store.projects.module.setIsUpdating(false);
    }
  }

  function _performAdd(args) {
    let moduleIsTaken = store.projects.moduleList.some(
      (d) => d.module_name.toLowerCase() === args.module_name.toLowerCase()
    );

    if (moduleIsTaken) {
      message.warning({ content: 'Ooops! This Module already Exist!' });
    } else {
      let data = _buildModuleDetail(args);
      form.resetFields();
      store.projects.setCurrentIcon('');
      store.projects.setAddModuleToList(data);
    }
  }

  function _generateUuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function _buildModuleDetail(moduleData) {
    let { module_name, module_icon } = moduleData;

    return {
      tempId: _generateUuidv4(),
      project_id: store.projects.single.id,
      module_name: module_name,
      menu_name: module_name,
      icon: `<${module_icon} />`,
      icon_name: module_icon,
      route: `Routes.${_toSnakeCase(module_name).toUpperCase()}_ROUTE`
    };
  }

  function _toSnakeCase(string) {
    return string
      .replace(/\W+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word) => word.toLowerCase())
      .join('_');
  }

  async function _showresultMessage(error, { successMessage }) {
    return new Promise((resolve, reject) => {
      if (error) {
        message.error({ content: t(error.response.data.error.message), key: 'create' });

        reject(false);
      } else {
        message.success({ content: successMessage, key: 'create' });
        resolve(true);
      }
    });
  }

  return {
    handleNavigateToCreateProject,
    handleSubmitAppDetails,
    getProjects,
    getDevelopersEmail,
    getSingleProject,
    handleBeginDownlaodApp,
    handleSumbitAesthethics,
    onChange,
    onPreview,
    onRemoveOrDeleteImage,
    generateDbDataFormData,
    handleSubmitAppDbDetails,
    handleChangeTab,
    handleNextStepAfterImageUpload,
    generateColumnsForDbDetails,
    toggleIconsModal,
    handleChooseAnIcon,
    handleSubmitModuleDetails,
    handleRemoveModule,
    handleUpdateModule,
    handleSubmitModule
  };
};

export default ProjectController;
