import { types, flow } from 'mobx-state-tree';
import Axios from 'axios';

export default types
  .model('TranslationModel', {
    id: types.optional(types.integer, 0),
    translation_key: types.optional(types.string, ''),
    en: types.optional(types.string, ''),
    de: types.optional(types.string, ''),
    key: types.optional(types.integer, 0)
  })
  .actions((self) => ({}))
  .views((self) => ({}));
