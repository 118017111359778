import { types } from 'mobx-state-tree';

/**CORE IMPORTS */
import ApplyChildCrud from '@core_models/Helpers/ChildCrud';

const API_PATH = '/api/aesthetics';

export default types
  .model('AestheticsModel', {
    project_id: types.optional(types.number, 0),
    main_theme_color: types.optional(types.string, ''),
    secondary_theme_color: types.optional(types.string, ''),
    tertiary_theme_color: types.optional(types.string, ''),
    quaternary_theme_color: types.optional(types.string, ''),
    tab_card_theme_color: types.optional(types.string, ''),
    tab_card_text_color: types.optional(types.string, ''),
    isCreating: types.optional(types.boolean, false)
  })
  .views((self) => ({}))
  .actions((self) => ({
    ...ApplyChildCrud(API_PATH, self)
  }));
