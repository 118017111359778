import { types } from 'mobx-state-tree';
import ProjecstModel from './ProjecstModel';

import ApplyCrud from '@core_models/Helpers/Crud';
import Axios from 'axios';
import AestheticsModel from './AestheticsModel';
import AppImagesModel from './AppImagesModel';
import DatabaseConfigModel from './DatabaseConfigModel';
import ModuleModel from './ModuleModel';

const API_PATH = '/api/projects';
const CLONE_BASE_URL = 'http://localhost:9948';
const CLONE_URL = `${CLONE_BASE_URL}/clone`;
const MIGRATION_URL = `${CLONE_BASE_URL}/migration`;

const DOWNLOAD_BASE_URL = '/api/download_app';
const DOWNLOAD_PATH = '/api/download_app/download';

//finalizing

export default types
  .model('ProjectsState', {
    state: types.optional(types.array(ProjecstModel), []),
    aesthetics: types.optional(AestheticsModel, {}),
    databaseConfig: types.optional(DatabaseConfigModel, {}),
    appImages: types.optional(AppImagesModel, {}),
    module: types.optional(ModuleModel, {}),
    moduleList: types.optional(types.array(ModuleModel), []),
    single: types.optional(ProjecstModel, {}),
    devEmails: types.optional(types.array(types.string), []),
    currentStep: types.optional(types.string, '0'),
    isSubmittingAppDetails: types.optional(types.boolean, false),
    isGettingProjects: types.optional(types.boolean, false),
    tabActiveKey: types.optional(types.number, 0),
    isDownloadingApp: types.optional(types.boolean, false),
    showIconsModal: types.optional(types.boolean, false),
    currentIcon: types.optional(types.string, ''),
    downloadButtonName: types.optional(types.string, 'Download')
  })
  .views((self) => ({}))
  .actions((self) => ({
    ...ApplyCrud(API_PATH, self),

    setDevEmails(args) {
      let emails = args.map((data) => {
        return data.email;
      });

      self.devEmails = emails;
    },

    setDownloadButtonName(downloadButtonName) {
      self.downloadButtonName = downloadButtonName;
    },

    setAddModuleToList(newModule) {
      self.moduleList.push(newModule);
    },

    setUpdateModuleList(updatedModule) {
      self.moduleList = self.moduleList.map((d) => {
        if (d.tempId === self.module.currentUpdateId) {
          return { ...d, ...updatedModule };
        } else {
          return d;
        }
      });
    },

    removeModuleFromList(moduleToRemove) {
      self.moduleList.splice(
        self.moduleList.findIndex((data) => data.tempId === moduleToRemove.tempId),
        1
      );
    },

    setCurrentIcon(currentIcon) {
      self.currentIcon = currentIcon;
    },

    setShowIconsModal(showIconsModal) {
      self.showIconsModal = showIconsModal;
    },

    setDownloadingApp(isDownloadingApp) {
      self.isDownloadingApp = isDownloadingApp;
    },

    setSingle(args) {
      self.single = Object.assign(args);
    },

    setIsGettingProjects(isGettingProject) {
      self.isGettingProjects = isGettingProject;
    },

    setNextStep(nextStep) {
      self.currentStep = nextStep;
    },

    setIsAppDetailsSubmitting(isSubmitting) {
      self.isSubmittingAppDetails = isSubmitting;
    },

    async beginZipping() {
      try {
        let { data } = await Axios.post(`${CLONE_BASE_URL}/zipping`, {
          Project: self.single
        });

        return [data, null];
      } catch (error) {
        return [null, error];
      }
    },
    async finalizingApp() {
      try {
        let { data } = await Axios.post(`${CLONE_BASE_URL}/finalizing`, {
          Module: self.single.Module
        });

        return [data, null];
      } catch (error) {
        return [null, error];
      }
    },
    async beginMigration() {
      try {
        let { data } = await Axios.post(`${MIGRATION_URL}`, { Module: self.single.Module });

        return [data, null];
      } catch (error) {
        return [null, error];
      }
    },
    async beginClone() {
      try {
        let { data } = await Axios.get(`${CLONE_URL}`);

        return [data, null];
      } catch (error) {
        return [null, error];
      }
    },

    async beginDownload(projectId) {
      try {
        let result = await Axios.get(`${DOWNLOAD_BASE_URL}/download/${projectId}`);

        return [result, null];
      } catch (error) {
        return [null, error];
      }
    },
    async startDownload(repoName) {
      repoName = 'start-tempalte';
      Axios({
        url: `/api/download_app/begin_download/${repoName}.zip`, //your url
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${repoName}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    }
  }));
