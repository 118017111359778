import React, { Suspense } from 'react';
import { Card, Grid } from 'antd';

import './styles.css';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';
import { LoginForm } from '@core_modules/Login/components';

const { useBreakpoint } = Grid;

function Login() {
  const screens = useBreakpoint();

  console.log(`wew`);

  return (
    <Suspense fallback={FallBackLoaders.EmptyLoader}>
      <Card className={`shadow-lg ${screens.xs ? 'w-100 mx-4' : 'w-75'} p-4`}>
        <LoginForm />
      </Card>
    </Suspense>
  );
}

export default Login;
