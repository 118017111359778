import { types, flow, cast } from 'mobx-state-tree';
import Axios from 'axios';
import TranslationModel from './TranslationModel';

const API_PATH = '/api/translation';

export default types
  .model('TranslationState', {
    state: types.optional(types.array(TranslationModel), [])
  })
  .actions((self) => ({
    INSERT_TRANSLATION: flow(function* (row) {
      try {
        const { data } = yield Axios.post(API_PATH, { row });

        return [data, null];
      } catch (error) {
        return [null, error];
      }
    }),

    GET_TRANSLATIONS: flow(function* () {
      try {
        const { data } = yield Axios.get(API_PATH);

        self.state = cast(data);
        return [data, null];
      } catch (error) {
        console.log(error, 'sdfsdf');
        return [null, error];
      }
    }),

    GENERATE_JSON: flow(function* () {
      try {
        const { data } = yield Axios.get(`${API_PATH}/generate_json`);
        console.log(data);
        //  self.state = cast(data);
        return [data, null];
      } catch (error) {
        console.log(error, 'sdfsdf');
        return [null, error];
      }
    }),

    BEGIN_DOWNLOAD_TRANSLATION_ZIP: flow(function* () {
      let translationFileName = 'translations';

      let response = yield Axios({
        url: `${API_PATH}/begin_download`, //your url
        method: 'GET',
        responseType: 'blob' // important
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${translationFileName}.zip`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }),

    setTranslation(data) {
      console.log(data, 'setTranslation');
      self.state = data;
    }
  }))
  .views((self) => ({}));
