import React, { Suspense } from 'react';

/**APP IMPORTS */
import { ProjectHeader, ProjectList } from '@app_modules/Projects/components/Projects';

/**CORE IMPORTS */
import { FallBackLoaders } from '@core_common/components';

function Projects() {
  return (
    <>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ProjectHeader />
      </Suspense>
      <Suspense fallback={FallBackLoaders.EmptyLoader}>
        <ProjectList />
      </Suspense>
    </>
  );
}

export default Projects;
